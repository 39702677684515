var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"outline-gray-400 rounded-md p-6 flex flex-col justify-between h-full"},[_c('InputFieldComponent',{staticClass:"w-50p",attrs:{"label":"Practice Test Name","label-class":"text-base","placeholder":"SPECIAL PRACTICE TEST"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.title"}}),(_vm.type == 'mock-test')?_c('div',{staticClass:"mt-6"},[_c('label',{staticClass:"text-uppercase font-bold block text-base"},[_vm._v("Choose Mock Type")]),_c('RadioButtonComponent',{attrs:{"label-class":"text-lg text-uppercase","items":[
                {id:'FULL_MOCK', label: 'Full Mock'},
                {id:'SECTION_WISE_MOCK', label: 'Section Wise Mock'},
                {id:'PARTIAL_MOCK', label: 'Partial / Mini Mock'},
              ]},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.type"}})],1):_vm._e(),(_vm.type == 'quiz-test')?_c('div',{staticClass:"mt-6"},[_c('label',{staticClass:"text-uppercase font-bold block text-base"},[_vm._v("Choose Template")]),_c('RadioButtonComponent',{attrs:{"label-class":"text-lg text-uppercase","items":[
                {id:'One_by_One', label: 'One by One'},
                {id:'All_in_One', label: 'All in One'},
              ]},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.type"}})],1):_vm._e(),(_vm.form.type == 'SECTION_WISE_MOCK')?_c('div',{staticClass:"mt-6"},[_c('label',{staticClass:"text-uppercase font-bold block text-base"},[_vm._v("Choose Section")]),_c('RadioButtonComponent',{attrs:{"label-class":"text-lg text-uppercase","items":[
                {id:'speaking', label: 'Speaking'},
                {id:'writing', label: 'Writing'},
                {id:'reading', label: 'Reading'},
                {id:'listening', label: 'Listening'}
              ]},model:{value:(_vm.section),callback:function ($$v) {_vm.section=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"section"}})],1):_vm._e(),(_vm.type == 'pte-practice')?[_c('div',{staticClass:"mt-6"},[_c('label',{staticClass:"text-uppercase font-bold block text-base"},[_vm._v("Choose Section")]),_c('CheckboxComponent',{attrs:{"label-outline":false,"block":false,"label-class":"text-lg text-uppercase","items":[
                    {id:'SPEAKING', label: 'Speaking'},
                    {id:'WRITING', label: 'Writing'},
                    {id:'READING', label: 'Reading'},
                    {id:'LISTENING', label: 'Listening'}
                  ]},model:{value:(_vm.form.sections),callback:function ($$v) {_vm.$set(_vm.form, "sections", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.sections"}})],1)]:_vm._e(),(_vm.form.type == 'PARTIAL_MOCK')?_c('div',{staticClass:"mt-6"},[_c('label',{staticClass:"text-uppercase font-bold block text-base"},[_vm._v("Choose Section")]),_c('CheckboxComponent',{attrs:{"label-outline":false,"block":false,"label-class":"text-lg text-uppercase","items":[
                {id:'SPEAKING', label: 'Speaking'},
                {id:'WRITING', label: 'Writing'},
                {id:'READING', label: 'Reading'},
                {id:'LISTENING', label: 'Listening'}
              ]},model:{value:(_vm.form.sections),callback:function ($$v) {_vm.$set(_vm.form, "sections", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.sections"}})],1):_vm._e(),(_vm.type == 'mock-test' || _vm.type == 'quiz-test')?_c('div',[_c('TextAreaComponent',{attrs:{"label":"Start Message","label-class":"text-base"},model:{value:(_vm.form.start_message),callback:function ($$v) {_vm.$set(_vm.form, "start_message", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.start_message"}}),_c('TextAreaComponent',{attrs:{"label":"End Message","label-class":"text-base"},model:{value:(_vm.form.end_message),callback:function ($$v) {_vm.$set(_vm.form, "end_message", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.end_message"}})],1):_vm._e(),_c('TextAreaComponent',{attrs:{"label":"Short Note","label-class":"text-base"},model:{value:(_vm.form.note),callback:function ($$v) {_vm.$set(_vm.form, "note", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.note"}}),_c('div',{staticClass:"flex justify-end mt-4"},[_c('md-button',{staticClass:"text-uppercase",on:{"click":_vm.formClear}},[_vm._v("Clear all")]),_c('Button',{staticClass:"bg-victoria rounded text-white mr-0",attrs:{"loading":_vm.loading,"label":"Next","type":"button"},on:{"click":_vm.submit}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }