<template>
  <div class="outline-gray-400 rounded-md p-6 flex flex-col justify-between h-full test-time-set">
    <h2 class="text-uppercase mb-6">Time set</h2>
    <div class="md-layout md-gutter quiz_set" v-if="type == 'quiz-test'">
      <div class="md-layout-item md-xlarge-size-35 md-large-size-35 md-medium-size-35 md-small-size-50 md-xsmall-size-100">
        <TimeSection label="TEXT+TEXT" type="quiz" class="md-gg-pte">
          <TestTimeSet 
            v-for="(item, index) in this.quiz"
            :item="item"
            :key="index"
            type="quiz"
          />
        </TimeSection>
      </div>
      <div class="md-layout-item md-xlarge-size-35 md-large-size-35 md-medium-size-35 md-small-size-50 md-xsmall-size-100">
        <TimeSection label="IMAGE+TEXT" type="quiz" class="md-gg-pte">
          <TestTimeSet 
            v-for="(item, index) in this.quiz"
            :item="item"
            :key="index"
            type="quiz"
          />
        </TimeSection>
      </div>
      <br>
      <div class="md-layout-item md-xlarge-size-35 md-large-size-35 md-medium-size-35 md-small-size-50 md-xsmall-size-100">
        <TimeSection label="AUDIO+TEXT" type="quiz" class="md-gg-pte">
          <TestTimeSet 
            v-for="(item, index) in this.quiz"
            :item="item"
            :key="index"
            type="quiz"
          />
        </TimeSection>
      </div>
      <div class="md-layout-item md-xlarge-size-35 md-large-size-35 md-medium-size-35 md-small-size-50 md-xsmall-size-100">
        <TimeSection label="VIDEO+TEXT" type="quiz"  class="md-gg-pte">
          <TestTimeSet 
            v-for="(item, index) in this.quiz"
            :item="item"
            :key="index"
            type="quiz"
          />
        </TimeSection>
      </div>
    </div>
    <div class="md-gutter" v-else>
      <div v-if="section.reading" class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-50">
        <TimeSection label="Reading" class="md-gg-pte">
            <TestTimeSet 
              v-for="(item, index) in section.reading"
              :item="item"
              :key="index"
            />
          </TimeSection>
      </div>
      
      <div v-if="section.writing" class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-50">
        <TimeSection label="Writing" class="md-gg-pte">
            <TestTimeSet 
              v-for="(item, index) in section.writing"
              :item="item"
              :key="index"
            />
          </TimeSection>
      </div>

      <!-- <div class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-50">
        <TimeSection label="Reading" style="visibility: hidden" />
      </div> -->

      <div v-if="section.listeningSingle" class="md-layout-item md-layout md-xsmall-size-100 md-small-size-100 md-medium-size-50">
          <TimeSection label="Listening" class="md-gg-pte">
            <TestTimeSet 
              v-for="(item, index) in section.listeningSingle"
              :item="item"
              :key="index"
            />
            <TestTimeSet 
              v-for="(item, index) in section.listeningMultiple"
              :item="item"
              :key="index"
            />
          </TimeSection>
      </div>
        
       <div v-if="section.speaking" class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-50">
          <TimeSection label="Speaking" class="md-gg-pte">
            <TestTimeSet 
              v-for="(item, index) in section.speaking"
              :item="item"
              :key="index"
            />
          </TimeSection>
      </div>
    </div>
    

    <div class="flex justify-end mt-4">
      <Button
        :loading="loading"
        class="bg-victoria rounded text-white mr-0"
        label="Next"
        type="button"
        @click="submit"
        >
      </Button>
    </div>
  </div>
</template>

<script>
import TestTimeSet from '@/components/molecule/test-creation/TestTimeSet';
import TimeSection from '@/components/molecule/test-creation/TimeSection';
import { filter, reject } from 'lodash';
import { Button } from '@/components'
export default {
  components: {
    Button,
    TestTimeSet,
    TimeSection
  },
  data() {
    return {
      loading: false,
      answerTime: '00:00',
      boolean: false,
      quiz: [ 
        { id: "1", slug: "text_text", title: "text+text",answer_time: 0,preparation_time: 0}
      ]
    }
  },
  computed: {
    section() {
      let data = {};
      let exist = localStorage.getItem('selectedSectionsUpdated');
      if(!exist) {
        let sections = JSON.parse(localStorage.getItem('selectedSections'));
        sections.forEach(item => {
        let key = item.label.toLowerCase();
        if(key != 'listening') {
          data[key] = item.types;
        } else {
          data[`${key}Single`] = filter(item.types, {'slug': 'summarize-spoken-test'})
          data[`${key}Multiple`] = reject(item.types, {'slug': 'summarize-spoken-test'})
        }
        });
        return data;
      }

      return JSON.parse(exist);
      
    },
    type() {
      return this.$route.query.type
    },
  },
  mounted(){
    console.log(this.section.writing)
  },
  methods: {
    async submit() {
      this.loading = false;
      
      localStorage.setItem('selectedSectionsUpdated', JSON.stringify(this.section));

       this.$router.push({
          name: 'testCreation.numberOfQuestion',
          query: {type: this.type}
        });
    }
  }
};
</script>