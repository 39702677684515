import cms from '@/data/cms';
import { find } from 'lodash';


export function slugify(str)
{
    str = str.replace(/^\s+|\s+$/g, '');

    // Make the string lowercase
    str = str.toLowerCase();

    // Remove accents, swap ñ for n, etc
    var from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
    var to   = "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    // Remove invalid chars
    str = str.replace(/[^a-z0-9 -]/g, '') 
    // Collapse whitespace and replace by -
    .replace(/\s+/g, '-') 
    // Collapse dashes
    .replace(/-+/g, '-'); 

    return str;
}

export const getDomain = (url, subdomain) => {

    subdomain = subdomain || false;

    url = url.replace(/(https?:\/\/)?(www.)?/i, '');

    if (!subdomain) {
        url = url.split('.');

        url = url.slice(url.length - 2).join('.');
    }

    if (url.indexOf('/') !== -1) {
        return url.split('/')[0];
    }
    
    return url;
}


export const addSubdomain = subdomain => {

    var protocal = window.location.protocol;
    var url = (process.env.NODE_ENV === "development") ? process.env.VUE_APP_ORIGIN_URL_DEV: process.env.VUE_APP_ORIGIN_URL_PROD;
    if(!url) url = 'apical.io';

    return protocal+'//'+subdomain+'.'+getDomain(url);
}
/*
    Time formatter
    We get the time in second from api. We need the time minute and second
    formate for time picker.
*/

export const secondToTime = seconds => {
    let minute = `${('0' + Math.trunc(parseInt(seconds)/60)).slice(-2)}`;
    let second = `${('0' + Math.trunc(parseInt(seconds)%60)).slice(-2)}`;
    return `${minute}:${second}`
}


/*
    Seconde formatter
    We get the time in minute and second string formate from time picker, so for api
    requirement is second value format
*/

export const timeToSecond = time => {
    if(time == '') return null;
    let [minute, second] = time.split(":");
    return (parseInt(minute)*60) + parseInt(second)
}


/*
    We have dynamic component base on slug
    Every question has separate component they are connected to the slug name
*/
export const slugToComponent = slug => {
    let { component } = find(cms.questionTypes, ['slug',  slug]);
    return component || null;
}

/*
    Query data convert to ENUM with underscore
*/
export const queryToEnum = data =>  data.replace(/-/g, '_').toUpperCase();