<template>
  <div class="outline-gray-400 rounded-md p-6 flex flex-col justify-between h-full">
        <InputFieldComponent
            label="Practice Test Name"
            label-class="text-base"
            placeholder="SPECIAL PRACTICE TEST"
            v-model.trim="form.title"
            class="w-50p"
          />
          <div class="mt-6" v-if="type == 'mock-test'">
            <label class="text-uppercase font-bold block text-base">Choose Mock Type</label>
            <RadioButtonComponent
              label-class="text-lg text-uppercase"
              :items="[
                {id:'FULL_MOCK', label: 'Full Mock'},
                {id:'SECTION_WISE_MOCK', label: 'Section Wise Mock'},
                {id:'PARTIAL_MOCK', label: 'Partial / Mini Mock'},
              ]"
              v-model.trim="form.type"
            />
          </div>
          <div class="mt-6" v-if="type == 'quiz-test'">
            <label class="text-uppercase font-bold block text-base">Choose Template</label>
            <RadioButtonComponent
              label-class="text-lg text-uppercase"
              :items="[
                {id:'One_by_One', label: 'One by One'},
                {id:'All_in_One', label: 'All in One'},
              ]"
              v-model.trim="form.type"
            />
          </div>
          <div class="mt-6" v-if="form.type == 'SECTION_WISE_MOCK'">
            <label class="text-uppercase font-bold block text-base">Choose Section</label>
            <RadioButtonComponent
              
              label-class="text-lg text-uppercase"
              :items="[
                {id:'speaking', label: 'Speaking'},
                {id:'writing', label: 'Writing'},
                {id:'reading', label: 'Reading'},
                {id:'listening', label: 'Listening'}
              ]"
              v-model.trim="section"
            />
          </div>
          <template v-if="type == 'pte-practice'">
              <div class="mt-6">
                <label class="text-uppercase font-bold block text-base">Choose Section</label>
                <CheckboxComponent
                  :label-outline="false"
                  :block="false"
                  label-class="text-lg text-uppercase"
                  :items="[
                    {id:'SPEAKING', label: 'Speaking'},
                    {id:'WRITING', label: 'Writing'},
                    {id:'READING', label: 'Reading'},
                    {id:'LISTENING', label: 'Listening'}
                  ]"
                  v-model.trim="form.sections"
                />
              </div>
              <!-- <div>
                <label class="text-uppercase font-bold block text-base">Choose Items</label>
                <RadioButtonComponent
                  block
                  align="align-left"
                  label-class="text-lg text-uppercase"
                  :items="items"
                  v-model.trim="questions_type"
                />
              </div> -->
          </template>
          <div class="mt-6" v-if="form.type == 'PARTIAL_MOCK'">
            <label class="text-uppercase font-bold block text-base">Choose Section</label>
            <CheckboxComponent
              :label-outline="false"
              :block="false"
              label-class="text-lg text-uppercase"
              :items="[
                {id:'SPEAKING', label: 'Speaking'},
                {id:'WRITING', label: 'Writing'},
                {id:'READING', label: 'Reading'},
                {id:'LISTENING', label: 'Listening'}
              ]"
              v-model.trim="form.sections"
            />
          </div>
          <div v-if="type == 'mock-test' || type == 'quiz-test'">
              <TextAreaComponent
                label="Start Message"
                label-class="text-base"
                v-model.trim="form.start_message"
              />
              <TextAreaComponent
                label="End Message"
                label-class="text-base"
                v-model.trim="form.end_message"
              />
          </div>
        <TextAreaComponent
          label="Short Note"
          label-class="text-base"
          v-model.trim="form.note"
        />

        <div class="flex justify-end mt-4">
          <md-button @click="formClear" class="text-uppercase">Clear all</md-button>
          <Button
            :loading="loading"
            class="bg-victoria rounded text-white mr-0"
            label="Next"
            type="button"
            @click="submit"
            >
          </Button>
        </div>
  </div>
</template>

<script>
import {
  Button,
  InputFieldComponent,
  TextAreaComponent,
  RadioButtonComponent,
  CheckboxComponent
  } from "@/components"
import { mapActions, mapMutations, mapGetters } from 'vuex';
export default {
  name: "TestInformationView",
  components: {
    Button,
    RadioButtonComponent,
    InputFieldComponent,
    CheckboxComponent,
    TextAreaComponent
  },
  data() {
    return {
      form: {
        start_message: '',
        end_message: '',
        note: '',
        type: "FULL_MOCK",
        title: '',
        instruction: "",
        duration : "10",
        create_type: "MANUAL",
        active: 0,
        sections: [],
        times: [],  
        questions: [],  
      },
      section: '',
      // questions_type: '',  
      loading: false,
      handleSection: '',
    }
  },
  computed: {
    ...mapGetters({
      getQuestionTypes: "question/getQuestionTypes"
    }),
    type() {
      return this.$route.query.type
    },
    items() {
      return this.getQuestionTypes.map(item => {
        item.label = item.name;
        return item;
      })
    }
  },
  watch: {
    section(value) {
        if(this.type == 'pte-practice') {
            this.actGetQuestionTypes(`?section=${value}`);
        }
        this.form.sections = [
            {section: value}
        ]
        //this.$root.handleSection = this.form.sections[0].section;
       //console.log('ssdd',this.actGetQuestionTypes(`?section=${value}`))
    }
  },
  mounted(){
        console.log(this.getQuestionTypes)
  },
  methods: {
    ...mapMutations({
      setShowSnackbar: "setShowSnackbar"
    }),
    ...mapActions({
      actGetQuestionTypes: "question/actGetQuestionTypes",
      actTestCreationSave: "testCreation/actTestCreationSave",

    }),
    submit() {
      this.loading = true;
      if(this.form.type == 'PARTIAL_MOCK') {
          this.form.sections = this.form.sections.map(item => {
            
              return {
                  section: item
              }
          })
          console.log("pmock",this.form.sections)
      }
      if(this.$route.query.type == 'pte-practice'){
        this.form.type = "PTE_PRACTICE";
        this.form.sections = this.form.sections.map(item => {
            
              return {
                  section: item
              }
          })
          console.log("pmock",this.form.sections)
        //   console.log("mock",this.form.questions_type)
        // this.form.questions = [
        //     {id : this.form.questions}
        // ]
      }
      
      if(this.form.type == 'FULL_MOCK') {
        let fullSections = ['READING', 'WRITING', 'SPEAKING', 'LISTENING']
        this.form.sections = fullSections.map(item => {
              return {
                  section: item
              }
          })
          console.log("mock",this.form.sections)
      }
      delete this.form.section;
      this.actTestCreationSave(this.form)
      .then(({sections, details, message}) => {
        // if(this.type == 'pte-practice') {
        //   this.$router.push({
        //     name: 'testCreation.timeSets',
        //     query: {type: this.type}
        //   });
        // } else{
          this.$router.push({
            name: 'testCreation.timeSet',
            query: {type: this.type}
            
          });
          console.log(this.type)
        // }
        this.setShowSnackbar(message);
        localStorage.setItem('test', JSON.stringify(details));
        localStorage.setItem('selectedSections', JSON.stringify(sections));
        localStorage.removeItem('selectedSectionsUpdated');
      }).catch(error => {
          console.log(error);
          
      }).finally(() => this.loading = false);
    },
    formClear() {
      let kyes = ['title', 'start_message', 'end_message', 'note'];
      kyes.forEach(key => this.form[key] = '');
      this.form.type = 'FULL_MOCK'
    }
  },
  created() {
    this.actGetQuestionTypes(`?section=speaking`)
  }
}
</script>