<template>

    <!-- <div>
      <h2 class="text-lg text-uppercase mb-4">Number of question preset</h2>
      <div class="md-layout md-gutter">
        <div
          v-for="(item, index) in items" :key="index"
          class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-50"
          :style="[!isListening(item) ? {'flex': '0 1 20%'} : {'flex': '0 1 40%' } ]"
        >
        <h3 class="text-uppercase mb-4 text-victoria">{{ item.name }}</h3>
        <template v-if="!isListening(item)" >
          <QuestionNumberInput v-for="(type, j) in item.types" :key="j" :label="type.label" />
        </template>

        <div v-else class="md-layout md-gutter">
          <div class="md-layout-item " style="flex: 1 1">
            <QuestionNumberInput v-for="(type, j) in item.special" :key="j" :label="type.label" />
          </div>
          
          <div class="md-layout-item">
            <QuestionNumberInput v-for="(type, j) in item.types" :key="j" :label="type.label" />
          </div>

        </div>
      </div>
    </div>
  </div>
    <div class="flex justify-end mt-4">
      <md-button class="text-uppercase">Clear all</md-button>
      <router-link :to="{name: 'testCreation.chooseQuestion'}">
        <md-button type="submit" class="text-uppercase mr-0 bg-victoria text-white rounded">Next</md-button>
      </router-link>
    </div> -->

  <div class="outline-gray-400 rounded-md p-6 flex flex-col justify-between h-full">
    <h2 class="text-uppercase mb-6">Number of question preset</h2>
    <div class="md-layout md-gutter">
      <div v-if="section.speaking" style="flex: 0 1 20%" class="mb-6 mt-4 md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-50">
          <h2 class="text-victoria text-base text-uppercase mb-4">Speaking</h2>
           <QuestionNumberInput
            v-for="(type, index) in section.speaking"
            :key="index"
            :item="type"
          />
      </div>

      <div v-if="section.writing" style="flex: 0 1 20%" class="mb-6 mt-4 md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-50">
        <h2 class="text-victoria text-base text-uppercase mb-4">writing</h2>
        <QuestionNumberInput
            v-for="(type, index) in section.writing"
            :key="index"
            :item="type"
          />
      </div>

      <div v-if="section.reading" style="flex: 0 1 20%" class="mb-6 mt-4 md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-50">
        <h2 class="text-victoria text-base text-uppercase mb-4">reading</h2>
        <QuestionNumberInput
            v-for="(type, index) in section.reading"
            :key="index"
            :item="type"
        />
      </div>

      <div v-if="section.listeningSingle" style="flex: 0 1 40%" class="md-layout-item md-layout md-xsmall-size-100 md-small-size-100 md-medium-size-50">
          <div class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-50">
        <h2 class="text-victoria text-base text-uppercase mb-4">listening</h2>
            <QuestionNumberInput
              v-for="(type, index) in section.listeningSingle"
              :key="index"
              :item="type"
              :condition="condition"
            />
            <QuestionNumberInput
              v-for="(type, index) in section.listeningMultiple"
              :key="index"
              :item="type"
              :condition="condition"
            />
          </div>
      </div>

    </div>

    <div class="flex justify-end mt-4">
      <md-button class="text-uppercase">Clear all</md-button>
      <Button
        :loading="loading"
        class="bg-victoria rounded text-white mr-0"
        label="Next"
        type="button"
        @click="submit"
        >
      </Button>
    </div>
  </div>
</template>


<script>
import QuestionNumberInput  from '@/components/molecule/test-creation/QuestionNumberInput';
import  data  from '@/data/test-creation/data';
import { Button } from '@/components';
import { flatten } from 'lodash';
export default {
  components: {
    Button,
    QuestionNumberInput
  },
  data() {
    return {
      loading: false,
      items: data.SectionTypes,
      condition:false
    }
  },
  computed: {
    section() {
      return JSON.parse(localStorage.getItem('selectedSectionsUpdated'));
    },
    type() {
      return this.$route.query.type
    },
  },
 
  methods: {
    
    submit() {
     let data = [];
      let test = JSON.parse(localStorage.getItem('test'));
      Object.keys(this.section).map(item => {
       data.push(this.section[item]);
      })
      test.times = flatten(data).map(item => {
        return {
          question_type_id: item.id,
          preparation_time: item.preparation_time,
          answer_time: item.answer_time,
          is_beep: item.is_beep,
          quantity: item.quantity || 0
        }
      });
      //test.sections = [{section: "READING"}, {section: "WRITING"}]
      //test.instruction = "",
      //test.duration = "10";
     // test.end_message = "No extra time will be allowed at the end of the test";
     // test.instruction = "Please don't talk to each other while the exams are running";
     // test.note = "This test will be used in another test's subsidary part";
     // test.start_message = "This test will be conducted to ensure the student's real calibre"

     
      let excludes = ['id', 'created_by', 't_id', 'created_at'];

      excludes.forEach(item => delete test[item])

      localStorage.setItem('testQuestionCount', JSON.stringify(test));

       this.$router.push({
          name: 'testCreation.createType',
          query: {type: this.type}
        });
  }
  }
}
</script>