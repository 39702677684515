<template>
  <div class="outline-gray-400 rounded-md p-4 flex flex-col justify-between h-full">
    <div class="flex justify-between align-center pb-5" style="border-bottom: 1px solid #cbd5e0">
      <div class="flex align-center">
        <div>
          <span v-for="(heading , index) in section"
              :key="index" style="display:flex;justify-content: space-between;padding:3px">
          <h2 class="text-uppercase mr-2" >{{ heading.label }} </h2>
          <span class="bg-victoria text-white rounded px-1 text-capitalize mx-2">{{ heading.types[index].short_title}}</span>
          </span>
        </div>
        <SearchBox
          v-model="keyword"
          placeholder="Search by question name"
          class="p-2 w-48 mx-2"
          @submit="submitSearchKeyword"
          />
      </div>
      <div class="flex align-center">
        <span class="outline-gray-400 rounded px-1 mr-2">{{ getCartQuestions.length }} of {{this.getSectionWiseQuestion.length}} Question selected</span>
        <span class="outline-gray-400 rounded px-1 ml-2">00:00</span>
      </div>
    </div>

    <div class="flex mt-3">
      <div class="basis">
       <Table :items="questions" hover-action @onSelect="onSelect">
          <template slot="row" slot-scope="{ item }">
            <md-table-cell md-label="SL" md-numeric style="width:40px">{{ item.sl}}</md-table-cell>
            <md-table-cell md-sort-by="q_id" md-label="Q_ID" style="width: 70px">{{ item.q_id }}</md-table-cell>
            <md-table-cell md-sort-by="index" md-label="INDEX"> {{ item.index }} </md-table-cell>
            <md-table-cell md-sort-by="select" md-label="Select" > 
              <span v-if="item.isSelected">
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24">
                  <path fill="#00df05" d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M17.7,9.7l-7,7C10.5,16.9,10.3,17,10,17s-0.5-0.1-0.7-0.3l-3-3c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l2.3,2.3l6.3-6.3c0.4-0.4,1-0.4,1.4,0S18.1,9.3,17.7,9.7z"  />
                </svg> 
              </span> 
            </md-table-cell>
          </template>
        </Table>
      </div>
      <div @click="toggle = !toggle" style="max-width: 40px; min-width: 40px" class="bg-athens-gray center pointer">
        <md-icon>{{ (toggle) ? 'keyboard_arrow_left' : 'keyboard_arrow_right' }}</md-icon>
      </div>
      <div :class="{'hidden': toggle || getCartQuestions.length < 1}" class="flex flex-col justify-between" style="width: 350px">
        <div>
          <div class="flex align-center p-4">
          <h3 class="text-uppercase ">Added Question </h3>
          <span class="ml-2 text-sm bg-victoria text-white rounded w-4 h-4 center">{{ getCartQuestions.length }}</span>
        </div>
        <Table :items="getCartQuestions" class="selected-question-list" >
          <template slot="row" slot-scope="{ item }">
            <md-table-cell> {{item.index}} </md-table-cell>
            <md-table-cell> 
              <svg @click="removeQuestion(item)" class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 40 40">
                <path fill="#f78f8f" d="M20,38.5C9.799,38.5,1.5,30.201,1.5,20S9.799,1.5,20,1.5S38.5,9.799,38.5,20S30.201,38.5,20,38.5z"/>
                <path fill="#c74343" d="M20,2c9.925,0,18,8.075,18,18s-8.075,18-18,18S2,29.925,2,20S10.075,2,20,2 M20,1 C9.507,1,1,9.507,1,20s8.507,19,19,19s19-8.507,19-19S30.493,1,20,1L20,1z"/>
                <path fill="#fff" d="M18.5 10H21.5V30H18.5z" transform="rotate(-134.999 20 20)"/>
                <path fill="#fff" d="M18.5 10H21.5V30H18.5z" transform="rotate(-45.001 20 20)"/>
              </svg> 
            </md-table-cell>
          </template>
        </Table>
        </div>
        <div class="text-center">
          <md-button @click="onCartClear" class="text-danger text-capitalize">Clear All</md-button>
        </div>
      </div>
    </div>
    <div class="flex justify-between mt-4">
      <Paginate
       :start="paginate.start"
       :end="10"
       :total="paginate.total"
       :limit="paginate.limit"
        v-on:start="onStart"
        v-on:end="onEnd"
      />
      <div>
        <Button
        :loading="loading"
        class="bg-victoria rounded text-white mr-0"
        label="Next"
        type="button"
        @click="submit"
        >
      </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { SearchBox, Table, Paginate, Button } from "@/components";
import data from '@/data/question-creation/data';
import { paginate } from "@/utils/paginate";
import { flatten} from 'lodash';
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
export default {
  components: {
    Table,
    Button,
    Paginate,
    SearchBox
  },
  data() {
    return {
      loading: false,
      keyword: '',
      toggle: true,
      item: null,
      paginate: paginate.get(),
      sections: [],
    }
  },
  computed: {
    ...mapGetters({
      getCartQuestions: "testCreation/getCartQuestions",
      getSectionWiseQuestion: "question/getSectionWiseQuestion",
    }),
    ...mapState({
      questions: state => state.question.sectionWiseQuestion
    }),
    section() {
      return JSON.parse(localStorage.getItem('selectedSections'));
    },
    questions() {
      console.log("rr",this.getSectionWiseQuestion)
      return this.getSectionWiseQuestion;
    }
   
  },
  mounted(){
    console.log(localStorage.getItem("selectedSections"))
  },
  methods: {
    ...mapMutations({
      setCartQuestions: "testCreation/setCartQuestions",
      setCartClear: "testCreation/setCartClear",
      setRemoveCartQuestion: "testCreation/setRemoveCartQuestion",
      setQuestionTypes: "question/setQuestionTypes",
      setSelectedQuestion: "question/setSelectedQuestion"
    }),
    ...mapActions({
      actSectionWiseQuestion: "question/actSectionWiseQuestion",
      actTestStepUpdate: "testCreation/actTestStepUpdate",
    }),
    submitSearchKeyword() {

    },
    onSelect(item) {
      if(item) {
        if(this.$route.query.type == "pte-practice"){
        this.setSelectedQuestion(item);
        this.setCartQuestions(item);
          
        } else {
          this.setSelectedQuestion(item);
          this.setCartQuestions(item);
        }
      }
    },
    onCartClear() {
      this.item = null;
      data.readingData.map(item => item.isSelected = false);
      this.setCartClear();
    },
    
    removeQuestion(item) {
      this.item = item;
      item.isSelected = false;
      this.setRemoveCartQuestion(item);

    },
    onStart(value) {
      this.paginate.start -= value;
      this.paginate.end -= value;
      console(value)
    },
    onEnd(value) {
      this.paginate.start += value;
      this.paginate.end += value;
      console(value)
    },
   async submit() {
      this.loading = true;
        let test = JSON.parse(localStorage.getItem('test'));
        let testData = JSON.parse(localStorage.getItem('testQuestionCount'));
        testData.questions = this.getCartQuestions.map(item => item.id);
        let response = await this.actTestStepUpdate({
          testId: test.id,
          payload: testData
        });
        localStorage.setItem('createTypeResponse',JSON.stringify(response));
        this.$router.push({
          name: 'testCreation.review',
          query: {type: this.type}
        });
        this.loading = false;
    },
    onQuestionTypes() {
      let records = [];
      let types = JSON.parse(localStorage.getItem('selectedSections'));
      types.forEach(item => {
        records.push(item.types);
      });
      return flatten(records);
    }
  },
  created() {
    this.setQuestionTypes(this.onQuestionTypes());
    let questionTypeId = this.onQuestionTypes()[0].id;
    this.actSectionWiseQuestion(`?question_type_id=${questionTypeId}`)
  }
}
</script>