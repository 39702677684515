<template>
  <div class="text-center">
    <svg class="w-40 mb-4" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32">
        <path fill="#00df05" d="M16 3C8.8 3 3 8.8 3 16C3 23.2 8.8 29 16 29C23.2 29 29 23.2 29 16C29 14.6 28.800781 13.200391 28.300781 11.900391L26.699219 13.5C26.899219 14.3 27 15.1 27 16C27 22.1 22.1 27 16 27C9.9 27 5 22.1 5 16C5 9.9 9.9 5 16 5C19 5 21.599609 6.1996094 23.599609 8.0996094L25 6.6992188C22.7 4.3992188 19.5 3 16 3 z M 27.300781 7.5L16 18.800781L11.699219 14.5L10.300781 16L16 21.699219L28.699219 9L27.300781 7.5 z" />
    </svg>
    <h2 class="text-uppercase">{{ message }}</h2>
    <h1 class="text-uppercase text-victoria">Successfully</h1>
    <md-button @click="$router.push({name: 'testCreation'})" class="text-white bg-victoria rounded px-4">Back to list</md-button>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String
    }
  }
}
</script>